import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Header from '@components/header'
import SnsTimeline from '@components/sns-timeline'
import Banner from '@components/banner'
import '@styles/foundation/reset.css'
import '@styles/foundation/base.scss'

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          baseTitle
        }
      }
    }
  `)

  return (
    <div className="layout">
      <div className="layout-inner">
        {children}
        <Banner />
        <SnsTimeline />
      </div>
      <Header siteTitle={data.site.siteMetadata?.baseTitle || `Title`} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
