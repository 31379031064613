const React = require("react")
const Layout = require("@components/layout").default

exports.wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

// ページ内リンク時に、ページ全体でなくmain要素のみをスクロールさせる
exports.shouldUpdateScroll = ({routerProps: { location }}) => {
  const targetPaths = ["/"]
  if (targetPaths.includes(location.pathname) && location.href.includes("/#")) {
    const scrollToId = location.hash.replace(/^#/, "") // #message等の接頭辞#を削除してスクロール先のidを取得
    const scrollToElement = document.getElementById(scrollToId);
    const scrollToOffset = scrollToElement.offsetTop
    const main = document.getElementById("main") // スクロール対象要素
    main.scrollTo(0, scrollToOffset)
    return false;
  }
  return true
}
