import React, { useEffect, useState, useRef } from 'react'
import '@styles/layout/sns-timeline.scss'

const SnsTimeline = () => {

  const iframeWrapper = useRef(null);
  const [dynamicHeight, setDynamicHeight] = useState('0');

  window.twttr = (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
      t._e.push(f);
    };

    return t;
  }(document, "script", "twitter-wjs"));

  useEffect(() => {
    const setHeight = () => {
      const headerHeight = document.getElementById("header").offsetHeight
      const height = window.innerHeight - headerHeight;
      setDynamicHeight('' + height);
      const iframe = iframeWrapper?.current?.getElementsByTagName('iframe')[0]
      if (iframe) {
        window.twttr.widgets.load()
      }
    };

    setHeight();

    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return (
    <div className="sns-timeline-wrapper" ref={iframeWrapper} key={dynamicHeight}>
      <a className="twitter-timeline" data-height={dynamicHeight} data-chrome="noheader noborders" href="https://twitter.com/AmanectJP">Tweets by AmanectJP</a>
      {/* <iframe
        title="sns-timeline"
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Famanectjp&tabs=timeline&width=380&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId"
        width="380"
        height="500"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay;
        clipboard-write;
        encrypted-media;
        picture-in-picture;
        web-share"
      /> */}
    </div>
  )
}

export default SnsTimeline
