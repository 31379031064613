import { Link } from 'gatsby'
import React, { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import '@styles/layout/header.scss'

const Header = ({ siteTitle }) => {

  const [isHumburger, changeHumburger] = useState(false)
  const navList = Object.freeze([
    {name: "お知らせ", path: '/#info'},
    {name: "代表ご挨拶", path: '/#message'},
    {name: "会社概要", path: '/#overview'},
    {name: "求人/採用の相談箱", path: '/#findwork'},
    {name: "お問い合わせ", path: '/#contact'},
    {name: "開発実績", path: '/#business'}
  ])

  return (
    <header id="header" className="header">
      <a href="https://www.green-japan.com/company/9465?mid=cm16_9465_230306"
      target="_blank" rel="noopener noreferrer" className="header__logo-wrapper">
        <div className="header__logo-link">
          <StaticImage className="header__logo-ant-pc" src="../images/ant_tate.png" alt="amanect_logo" placeholder="none"/>
          <StaticImage className="header__logo-ant-tab" src="../images/ant_yoko.png" alt="amanect_logo" placeholder="none" height="65"/>
        </div>
      </a>
      <button className="header__hamburger-icon" onClick={() => changeHumburger(!isHumburger)}>
        <span className={isHumburger ? 'header__hamburger-line-1--opened' : 'header__hamburger-line-1'}></span>
        <span className={isHumburger ? 'header__hamburger-line-2--opened' : 'header__hamburger-line-2'}></span>
        <span className={isHumburger ? 'header__hamburger-line-3--opened' : 'header__hamburger-line-3'}></span>
      </button>
      <div className={isHumburger ? 'header__hamburger-menu--opened' : 'header__hamburger-menu'}>
        <nav className="header__nav-wrapper">
          <ul className="header__nav">
            {navList.map((navItem) => {
              return (
                <li key={navItem.path} className="header__nav-item-wrapper">
                  <Link
                    className="header__nav-item"
                    to={navItem.path}
                    data-text={navItem.name}
                    onClick={() => changeHumburger(false)}
                    >{navItem.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className="header__copyright-wrapper">
          <small className="header__copyright">&copy;&nbsp;株式会社アマネクト</small>
        </div>
      </div>
    </header>
  )
}

export default Header
