import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "@styles/layout/banner.scss"

const Banner = () => {
  return (
    <aside className="banner">
      <div className="banner__icon-wrapper">
        <a
          href="https://www.green-japan.com/company/9465"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="green"
        >
          <StaticImage
            src="../images/ant_symbol.png"
            alt="green"
            placeholder="blurred"
          ></StaticImage>
        </a>
      </div>
      {/* <div className="banner__icon-wrapper">
        <a href="https://www.facebook.com/amanectjp" target="_blank" rel="noreferrer noopener" aria-label="facebook">
          <StaticImage src="../images/sns/facebook.png" alt="facebook" placeholder="blurred"></StaticImage>
        </a>
      </div> */}
      <div className="banner__icon-wrapper">
        <a
          href="https://twitter.com/AmanectJP"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="twitter"
        >
          {/* <StaticImage src="../images/sns/twitter.png" alt="twitter" placeholder="blurred"></StaticImage> */}
          <StaticImage
            src="../images/sns/x-twitter.svg"
            alt="twitter"
            placeholder="blurred"
          ></StaticImage>
        </a>
      </div>
      {/* <div className="banner__icon-wrapper">
        <a href="https://www.instagram.com/amanectjp" target="_blank" rel="noreferrer noopener" aria-label="instagram">
          <StaticImage src="../images/sns/Instagram.png" alt="Instagram" placeholder="blurred"></StaticImage>
        </a>
      </div> */}
    </aside>
  )
}

export default Banner
